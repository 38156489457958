import { Injectable } from '@angular/core'
import { ErrorElementData } from '../models/error-element-data';
import { AssetSubType } from '../../assets/models/asset-sub-type';
import { EventsService } from './events.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorParseService {
    
    result: ErrorElementData[] | string;


    constructor(private eventsService: EventsService,) {

    }

    /**
     * Parse authentication server error message and return appropriate error string
     * @param {Object} msg The error message object received from the server
     * @param {Boolean} isSocial Boolean indicating if auth request was done with social provider
     * @returns {string}
     */
    parseOAuthError = (msg: any, isSocial: boolean = false, data?: any): any => {

        switch (msg.status) {
            case 400:
                if (msg.responseJSON.error === 'invalid_grant') {
                    let method = isSocial ? 'social provider' : 'username or password';
                    this.result = 'The selected ' + method + ' you entered does not match our records.<br>Please double-check and try again.';
                }
                else if (msg.responseJSON.error === 'invalid_request') {
                    this.result = 'The request contains missing or wrong data.';
                }
                else if (msg.responseJSON.error === 'unsupported_grant_type') {
                    this.result = 'The request contains unsupported grant type.';
                }
                else if (msg.responseJSON.error === 'inactive_user') {
                    this.result = [
                        { 
                            text: 'The user email has not been confirmed yet.'
                        },
                        {
                            lineBreak: true
                        },
                        {
                            text: 'Please confirm your email address by pressing the link in your confirmation mail.'
                        },
                        {
                            lineBreak: true
                        },
                        {
                            text: 'If you don\'t have the mail, please press '
                        },
                        {
                            link: { text: 'here', url: 'activation', queryParams: data}
                        },
                        {
                            text: ' to send a new one.'
                        }
                    ];
                }
                break;
            case 401:
                this.result = 'Something went wrong with the request.<br>Please check with ' +
                    this.getLinkTag({
                        text: 'support@bigvu.tv',
                        href: 'mailto:support@bigvu.tv',
                    });
                break;
            case 500:
                this.result = 'Our server experiencing problems.<br>Please try again or check with ' +
                    this.getLinkTag({
                        text: 'support@bigvu.tv',
                        href: 'mailto:support@bigvu.tv',
                    });
                break;
            default:
                this.result = 'Something went wrong with the request.<br>Please check with ' +
                    this.getLinkTag({
                        text: 'support@bigvu.tv',
                        href: 'mailto:support@bigvu.tv',
                    });
                break;
        }
        return this.result;
    }

    /**
     * Parse registration server error message and return appropriate error string
     * @param msg The error message received from the server
     * @returns {string}
     */
    parseRegistrationError = (msg: any) => {
        
        switch (msg.responseJSON.code) {
            case '30006/1':
                this.result = [
                    { 
                        text: 'The email you have entered is already registered.'
                    },
                    {
                        lineBreak: true
                    },
                    {
                        text: 'Please go to '
                    },
                    {
                        link: { text: 'login', url: 'login'}
                    },
                    {
                        text: ' page or reset your password '
                    },
                    {
                        link: { text: 'here', url: 'forgot-password'}
                    }
                ];
                
                break;
            case '1400':
                this.result = 'Something went wrong.<br>Please try again or check with ' +
                    this.getLinkTag({
                        text: 'support@bigvu.tv',
                        href: 'mailto:support@bigvu.tv'
                    });
                break;
            case '10000/0':
                this.result = 'This user has been already invited to different organization.<br>Please contact ' +
                    this.getLinkTag({
                        text: 'support@bigvu.tv',
                        href: 'mailto:support@bigvu.tv',
                    });
                break;
            case '10000/1':
                this.result = 'The social provider already linked to a different user.';
                break;
            case '10000':
                this.result = 'This user has already been invited to another organization.';
                break;
            case '2002':
                this.result = 'Your Invitation has been expired';
                break;
            case '10001/0':
                this.result = 'Your organization is different from the organization you were invited to.<br>Please check with ' +
                    this.getLinkTag({
                        text: 'support@bigvu.tv',
                        href: 'mailto:support@bigvu.tv',
                    });
                break;
            case '20002/0':
                this.result = 'Your social provider token has been expired.<br>Please check with ' +
                    this.getLinkTag({
                        text: 'support@bigvu.tv',
                        href: 'mailto:support@bigvu.tv',
                    });
                break;
            case '20001/0':
                this.result = 'Can not retrieve email from the social provider.<br>Please check with ' +
                    this.getLinkTag({
                        text: 'support@bigvu.tv',
                        href: 'mailto:support@bigvu.tv',
                    });
                break;
            case '30014/0':
                if (msg.responseJSON.msg === 'invalid coupon') {
                    this.result = "This coupon has already been redeemed";
                }
                break;
            case '10000/2':
                this.result = 'open-existing-account-dialog'
                break;
            default:
                this.result = 'Something went wrong.<br>Please try again or check with ' +
                    this.getLinkTag({
                        text: 'support@bigvu.tv',
                        href: 'mailto:support@bigvu.tv',
                    });
                break;
        }
        return this.result;
    }

    /**
     * Parse forgot password server error message and return appropriate error string
     * @param msg The error message received from the server
     * @returns {string}
     */
    parseForgotPasswordError = (msg: any) => {

        switch (msg.responseJSON.code) {
            case '10001/0':
            case '30003/0':
                this.result = 'The requested email was not found in the system.<br>Please retry or check with ' +
                    this.getLinkTag({
                        text: 'support@bigvu.tv',
                        href: 'mailto:support@bigvu.tv',
                    });
                break;
            case '30004/0':
                this.result = 'Could not send email to the user.<br> Please retry or check with ' +
                    this.getLinkTag({
                        text: 'support@bigvu.tv',
                        href: 'mailto:support@bigvu.tv',
                    }) +
                    ' if this error occurs.';
                break;
            case '1400':
                this.result = 'Something went wrong.<br>Please try again or check with ' +
                    this.getLinkTag({
                        text: 'support@bigvu.tv',
                        href: 'mailto:support@bigvu.tv'
                    });
                break;
            case '2002':
            case '2005':
                this.result = [
                    { 
                        text: 'Your password reset link was already used.'
                    },
                    {
                        lineBreak: true
                    },
                    {
                        text: 'Please use the following '
                    },
                    {
                        link: { text: 'link', url: 'forgot-password'}
                    },
                    {
                        text: 'to send a new password reset request.'
                    }
                ];
                
                break;
            case '2004':
                this.result = [
                    { 
                        text: 'Your password reset link has been expired.'
                    },
                    {
                        lineBreak: true
                    },
                    {
                        text: 'Please use the following '
                    },
                    {
                        link: { text: 'link', url: 'forgot-password'}
                    },
                    {
                        text: 'to send a new password reset request.'
                    }
                ];

                break;
            default:
                this.result = 'Something went wrong with the request.<br>Please check with ' +
                    this.getLinkTag({
                        text: 'support@bigvu.tv',
                        href: 'mailto:support@bigvu.tv',
                    });
                break;
        }

        return this.result;
    }

    parseActivationError = (msg: any) => {

        switch (msg.responseJSON.code) {
            case '10001/0':
                this.result = "Requested user was not found in the system. Please check with " +
                    this.getLinkTag({
                        text: 'support@bigvu.tv',
                        href: 'mailto:support@bigvu.tv'
                    });
                break;
            case '1400':
            case '30004/0':
                this.result = "Something went wrong. Please check with " +
                    this.getLinkTag({
                        text: 'support@bigvu.tv',
                        href: 'mailto:support@bigvu.tv'
                    });
                break;
            case '2002':
            case '2004':
                this.result = "Your verification token is invalid or has been expired, please click below to resend.";
                break;
            case '30003/0':
                this.result = "Email address for this user was already verified";
                break;
            case '30003/1':
                this.result = [
                    { 
                        text: 'Email address was not found in the system. Please register '
                    },
                    {
                        link: { text: 'here', url: 'register'}
                    }
                ];
                
                break;
            default:
                this.result = 'Something went wrong with the request. Please check with ' +
                    this.getLinkTag({
                        text: 'support@bigvu.tv',
                        href: 'mailto:support@bigvu.tv',
                    });
                break;
        }
        return this.result;
    }

    parseChangeMailError = (msg: any) => {

        switch (msg.responseJSON.code) {
            case '10001/0':
                this.result = "Requested user was not found in the system. Please check with " +
                    this.getLinkTag({
                        text: 'support@bigvu.tv',
                        href: 'mailto:support@bigvu.tv'
                    });
                break;
            case '1400':
            case '30004/0':
                this.result = "Something went wrong. Please check with " +
                    this.getLinkTag({
                        text: 'support@bigvu.tv',
                        href: 'mailto:support@bigvu.tv'
                    });
                break;
            case '2002':
            case '2004':
                this.result = "Your verification token is invalid or has been expired.";
                break;
            case '30003/0':
            case '2005':
                this.result = "Email address for this user was already changed";
                break;
            case '30003/1':
                this.result = [
                    { 
                        text: 'Email address was not found in the system. Please register '
                    },
                    {
                        link: { text: 'here', url: 'register'}
                    }
                ];

                break;
            default:
                this.result = 'Something went wrong with the request.<br>Please check with ' +
                    this.getLinkTag({
                        text: 'support@bigvu.tv',
                        href: 'mailto:support@bigvu.tv',
                    });
                break;
        }
        return this.result;
    }

    parseCouponRedemptionError = (msg: any) => {
        switch (msg.responseJSON.code) {
            case '30014/0':
                return "This coupon has already been redeemed";
            case '2000':
                return "This coupon doesn't exist, or you've reached the maximum usage amount";
            default:
                break;
        }
    }

    parseTeleprompterError = (msg: string) => {
        switch (msg) {
            case "Requested device not found":
                return "Please allow camera and microphone permissions. Make sure the devices are available as well.";
            default:
                break;
        }
    }

    parseSubscriptionError = (errorCode: string): string => {

        switch (errorCode) {
            case "incomplete_cvc":
                this.result = "Wrong card details";
                break;
            case "incomplete_expiry":
                this.result = "Missing card details";
                break;
            case "invalid_expiry_year_past":
                this.result = "Wrong card details";
                break;
            case "invalid_number":
                this.result = "Wrong card details";
                break;
            case "20000/0":
                this.result = "Payment failed. Check your card details and try again";
                break;
            case "10006/1":
                this.result = "Cannot change currency while subscription is active";
                break;
            case "1400/4":
                this.result = "Unsupported currency";
                break;
            default:
                this.result = "Something went wrong with the request. Please contact our support for help";
                break;
        }

        return this.result;
    }

    parseCollectionErrors = (errorMessage: string, isSystem: boolean): string => {
        switch (errorMessage) {
            case isSystem && "Resource already exist":
                this.result = "Cannot have more than 1 system collection in the same language";
                break;
            case "Not allowed":
                this.result = "Permission Denied";
                break;
            case "User already invited":
                this.result = "User already invited";
                break;
            case "not in the invitation list":
                this.result = "You are not in the invitation list of this collection";
                break;
            case "Can't unpublish collection, published pages with this collection exists":
                this.result = "Can't unpublish collection, published pages with this collection exists"
                break;
            default:
                this.result = errorMessage;
                break;
        }

        return this.result;
    }

    parseAppsumoCollectionErrors = (error: any): string => {
        switch (error.responseJSON.code) {
            case '1400':
                return "Failed to create the collection. There is some missing data";
        }
    }

    parseAccountExportErrors = (errorCode: string): string => {
        switch (errorCode) {
            case '10006/0':
                return "Your account was exported less than a week ago, or it is currently in process";
            default:
                return "Failed to export your account. Please try again later";
        }
    }

    parseAccountDeleteErrors = (errorCode: string): string => {
        switch (errorCode) {
            case '11001/0':
                return "The delete request was sent to approval";
            case '10006/0':
                return "An admin cannot be dismissed from a workspace";
            case '10006/1':
                return "You can not delete your organization while you have an active subscription. You should unsubscribe and try again.";
            case '10006/7':
                return "The user does not belong to any other workspace. Please delete the user through Team Settings";
            default:
                return "Failed to delete the user's account";
        }
    }

    parseAccountDomainErrors = (errorCode: string): string => {
        switch (errorCode) {
            case "10001/0":
                return "This account doesn't have an attached domain";
            case "10000/0":
                return "This account has another connected domain";
            case "10004/0":
                return "The domain you want to remove doesn't exist";
            default:
                return "We couldn't complete your request. Please try again";
        }
    }

    parsePagesErrors = (errorCode: string, type?: string): string => {
        switch (errorCode) {
            case "10000/0":
                if(type === 'prefix'){
                    return "This name already exist, try a different prefix";
                } else if(type === 'suffix') {
                    return "This name already exist, try a different suffix";
                } else {
                    return "We couldn't complete your request. Please try again";
                }

            case '10006/0':
                return "Can't publish a page of unpublished collection";

            default:
                return "We couldn't complete your request. Please try again";
        }
    }

    parseUsedAssetError = (error: {msg: string, code: string}, deletedType: AssetSubType | 'story' | 'collection' | 'page'): string => {
        switch (error.code) {
            case '10006/0':
                const deletedFrom = this.findDeletedFrom(error.msg || "") || "entity";
                
                this.sendEvent(this.eventsService.events.trashbin.deleteError, { 
                    entity: deletedType, 
                    dependency: deletedFrom 
                });

                return `Can't delete this ${deletedType} used by a published ${deletedFrom}`;

            default:
                return "We couldn't complete your request. Please try again";
        }
    }

    parseSocialPostError = (error: { code: string, msg: string }): string => {
        if (error) {
            switch (error.code) {
                case "10006/0":
                    return "Post removal is not supported for this provider";
                default:
                    break;
            }
        }
    }

    parseSocialPostTaskError = (errorCode: string): string => {
        const RESYNC_ERRORS = [
            "MissingPermissions", 
            "ExpiredAccessToken", 
            "MissingPermissionPagesReadPermission",
            "UnknownError",
            "APIPermissionDenied",
        ];

        const POST_UNAVAILABLE_ERRORS = [
            "PostDeleted"
        ];

        if (RESYNC_ERRORS.indexOf(errorCode) > -1) {
            return "Social account not connected";
        } else if (POST_UNAVAILABLE_ERRORS.indexOf(errorCode) > -1) {
            return "Social post deleted";
        } else {
            return "Missing Post Data";
        }
    }

    findDeletedFrom = (msg: string): string => {
        const fromOptions = ['story', 'collection', 'page'];

        for (let index = 0; index < fromOptions.length; index++) {
            const option = fromOptions[index];
            if (msg.includes(option)) {
                return option;
            }
        }

        return "";
    }

    parseWorkspaceErrors = (error: {msg: string, code: string}): string => {
        switch (error.code) {
            case '10006/0':
                return "You can invite only to public workspaces";
            case '10006/2':
                return "You have reached the maximum workspaces count for this organization";
            case '10006/3':
                return "You have reached the maximum workspaces count for this organization";
            case '10006/5':
                return "This user is already a member of another organization";
            case '10006/7':
                return "This user is already a member of the current organization";
            case '30008/0':
                return "This is the only workspace for some of your members and it cannot be removed";
            case '10000/0':
                return "This organization name already exists";
            case '10001/2':
                return "Invited user must have an account";
            case '2001':
                return "Cannot invite user without a plan as editor. Please change role to viewer";
            case '2006':
                return "Invited guests must activate their account before they could join your organization";
            default:
                return ""
        }
    }

    parseOrganizationInvitationErrors = (errorCode: string): string => {
        switch (errorCode) {
            case "10001/0":
                return "One or more of the invitation paramters are invalid";
            case '2002':
                return "Invitation is expired. Please ask your organization admin to resend his invitation";
            default:
                return "";
        }
    }

    parseDeleteTakeErrors = (errorCode : string, msg: string) : string => {
        switch (errorCode) {
            case "10000/0":
                return "A video already deleted"
            case "10006/0":
                if(msg.includes('page')) {
                    return "A video that is used in a video page cannot be deleted "
                }else{
                    return "A video that is used in an interactive page cannot be deleted "
                }
            default:
                return "Please try again"
        }
    }

    /**
     * Helper function to build an <a> html tal
     * @param obj Input object, must include 'href' and 'text' keys
     * @returns {string}
     */
    getLinkTag = (obj: any) => {
        // ensure obj is indeed an object that contains both href and text attributes
        if (typeof obj === 'object' && obj.href && obj.text) {
            return '<a href = "' + obj.href + '">' + obj.text + '</a>';
        }
        else {
            return '';
        }
    }

    clearError = () => {
        this.result = undefined;
    }

    sendEvent = (name: string, data?: any) => {
        this.eventsService.log(name, data);
    }

}
